import {
  Modal as ModalContainer,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";

const Modal = ({
  children,
  isOpen,
  onClose,
  onSubmit,
  title,
  to,
  isCentered,
  ...props
}) => {
  return (
    <ModalContainer
      isCentered={isCentered || false}
      size={"4xl"}
      blockScrollOnMount={true}
      closeOnOverlayClick={true}
      onClose={onClose}
      isOpen={isOpen}
      {...props}
    >
      <ModalOverlay />
      <ModalContent pb="10px">
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>{children}</ModalBody>
      </ModalContent>
    </ModalContainer>
  );
};

export default Modal;
