import styled from "styled-components";
import ReactSelect from "react-select";
import { Switch, Box } from "@chakra-ui/react";
import Icon from "src/config/icon.js";

export const SwitchBtn = styled(Switch)`
  position: absolute;
  left: 0px;
  top: 50%;
  transform: translate(0, -50%);
`;

export const UploadBox = styled(Box)`
  text-align: center;
  padding: 80px 0px;
  border: 2px dashed #8aa6bc;
  border-radius: 12px;
  cursor: ${({ disabled }) => (disabled == true ? "auto" : "pointer")};
  opacity: ${({ disabled }) => (disabled == true ? 0.3 : 1)};
`;

export const ReactSelectComponent = styled(ReactSelect)`
  .css-1s2u09g-control {
    width: 100%;
    height: 65px;
    border-radius: 12px;
    border: none;
    background: rgb(188 217 245);
    cursor: pointer;
  }
  .css-1pahdxg-control,
  .css-1pahdxg-control:hover {
    height: 65px;
    border-radius: 12px;
    border: none;
    background: rgb(188 217 245);
  }
  .css-tlfecz-indicatorContainer {
    color: hsl(209deg 67% 66%);
  }
  .css-1okebmr-indicatorSeparator {
    background-color: hsl(209deg 67% 66%);
  }
  .css-14el2xx-placeholder {
    color: #333;
  }
  //class-popUp-searchInputBox
  .css-26l3qy-menu {
    z-index: 3;
    * {
      cursor: pointer;
    }
  }
  * {
    text-align: center;
    direction: rtl;
  }
`;
