const API_SLUGS = {
  login: "login",
  register: "register",
  blog: "blogs",
  categorie: "categories",
  user: "users",
  form: "forms",
};

export default API_SLUGS;
