export const IMAGES = {
  logo: require("src/images/files/logo.png"),
  cover1: require("src/images/files/cover1.jpg"),
  cover2: require("src/images/files/cover2.jpg"),
  cover3: require("src/images/files/cover3.jpg"),
  slide1: require("src/images/files/slide1.jpg"),
  slide2: require("src/images/files/slide2.jpg"),
  machinerySketch: require("src/images/files/machinery-sketch.png"),
  brandTree: require("src/images/files/brand-tree.png"),
  empty1: require("src/images/files/empty1.png"),
  empty2: require("src/images/files/empty2.png"),
  empty3: require("src/images/files/empty3.png"),
  consultation: require("src/images/files/consultation2.jpg"),
  implementation: require("src/images/files/implementation.jpg"),
  turnkey: require("src/images/files/turnkey.jpg"),
  servicesCover: require("src/images/files/services-cover.jpg"),
  productsCover: require("src/images/files/products-cover.png"),
  contactUsCover: require("src/images/files/contact-us-cover.jpg"),
  cooperationCover: require("src/images/files/cooperation-cover.jpg"),
  celebration: require("src/images/files/celebration.jpeg"),
};

export const PRODUCTS_IMAGES = {
  dispensing: {
    root: require(`src/images/files/dispensing/single/product/P1.jpg`),
    single: require(`src/images/files/dispensing/single/product/P1.jpg`),
    double: require(`src/images/files/dispensing/double/product/P1.jpg`),
    robotic: require(`src/images/files/dispensing/robotic/product/P1.jpg`),
    movable: require(`src/images/files/dispensing/movable/product/P1.jpg`),
    fixed: require(`src/images/files/dispensing/fixed/product/P1.jpg`),
  },
  mold: {
    root: require(`src/images/files/mold/plastic/product/P1.jpg`),
    plastic: require(`src/images/files/mold/plastic/product/P1.jpg`),
    rubber: require(`src/images/files/mold/rubber/product/P1.jpg`),
    stamping: require(`src/images/files/mold/stamping/product/P1.jpg`),
    extrusion: require(`src/images/files/mold/extrusion/product/P1.png`),
    tools: require(`src/images/files/mold/tools/product/P1.jpg`),
  },
  plastic: {
    root: require(`src/images/files/plastic/injection/product/P1.jpg`),
    film: require(`src/images/files/plastic/film/product/P1.jpg`),
    extrusion: require(`src/images/files/plastic/extrusion/product/P1.jpg`),
    recycle: require(`src/images/files/plastic/recycle/product/P1.jpg`),
    injection: require(`src/images/files/plastic/injection/product/P1.jpg`),
  },
  rubber: {
    root: require(`src/images/files/rubber/injection/product/P1.jpg`),
    injection: require(`src/images/files/rubber/injection/product/P1.jpg`),
    compression: require(`src/images/files/rubber/compression/product/P1.jpg`),
    mixing: require(`src/images/files/rubber/mixing/product/P1.jpg`),
    silicone: require(`src/images/files/rubber/silicone/product/P1.jpg`),
    extrusion: require(`src/images/files/rubber/extrusion/product/P1.jpg`),
  },
};

export const CAROUSEL_IMAGES = {
  dispensing: {
    root: require(`src/images/files/carousel/dispensing/single/product/P1.jpg`),
    single: require(`src/images/files/carousel/dispensing/single/product/P1.jpg`),
    double: require(`src/images/files/carousel/dispensing/double/product/P1.jpg`),
    robotic: require(`src/images/files/carousel/dispensing/robotic/product/P1.jpg`),
    movable: require(`src/images/files/carousel/dispensing/movable/product/P1.jpg`),
    fixed: require(`src/images/files/carousel/dispensing/fixed/product/P1.jpg`),
  },
  mold: {
    root: require(`src/images/files/carousel/mold/plastic/product/P1.jpg`),
    plastic: require(`src/images/files/carousel/mold/plastic/product/P1.jpg`),
    rubber: require(`src/images/files/carousel/mold/rubber/product/P1.jpg`),
    stamping: require(`src/images/files/carousel/mold/stamping/product/P1.jpg`),
    extrusion: require(`src/images/files/carousel/mold/extrusion/product/P1.png`),
    tools: require(`src/images/files/carousel/mold/tools/product/P1.jpg`),
  },
  plastic: {
    root: require(`src/images/files/carousel/plastic/injection/product/P1.jpg`),
    film: require(`src/images/files/carousel/plastic/film/product/P1.jpg`),
    extrusion: require(`src/images/files/carousel/plastic/extrusion/product/P1.jpg`),
    recycle: require(`src/images/files/carousel/plastic/recycle/product/P1.jpg`),
    injection: require(`src/images/files/carousel/plastic/injection/product/P1.jpg`),
  },
  rubber: {
    root: require(`src/images/files/carousel/rubber/injection/product/P1.jpg`),
    injection: require(`src/images/files/carousel/rubber/injection/product/P1.jpg`),
    compression: require(`src/images/files/carousel/rubber/compression/product/P1.jpg`),
    mixing: require(`src/images/files/carousel/rubber/mixing/product/P1.jpg`),
    silicone: require(`src/images/files/carousel/rubber/silicone/product/P1.jpg`),
    extrusion: require(`src/images/files/carousel/rubber/extrusion/product/P1.jpg`),
  },
};
