import { combineReducers } from "redux";
import appReducer from "src/redux/app/reducers";
import userReducer from "src/redux/user/reducers";
import dashboardReducer from "src/redux/dashboard/reducers";

export default combineReducers({
  app: appReducer,
  user: userReducer,
  dashboard: dashboardReducer,
});
