import React from "react";
import {
  Container,
  Flex,
  VStack,
  Center,
  HStack,
  StackDivider,
} from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Navbar from "src/components/dashboard/navbar";

const DashboardPage = () => {
  return (
    <Center minH={"100vh"} h="100%" w="100%" bg="#edf2f7">
      <Container
        w="100%"
        h="90vh"
        bg="#fff"
        maxW={"7xl"}
        borderRadius="30px"
        p={"0px"}
        overflow="hidden"
        boxShadow={"lg"}
      >
        <HStack
          flex={1}
          bg="#ffffff"
          w="100%"
          height="100%"
          align={"flex-start"}
          justif="flex-start"
          divider={<StackDivider borderColor="#e2e8f0" m={"0px !important"} />}
          overflow="scroll"
        >
          <Flex
            flex="3"
            height="100%"
            flexDirection="column"
            justif="flex-start"
            align="center"
            bg="white"
          >
            <Navbar />
          </Flex>
          <VStack flex="9" bg="transparent" height="100%" position={"relative"}>
            <Outlet />
          </VStack>
        </HStack>
      </Container>
    </Center>
  );
};

export default DashboardPage;
