import { SET_APP_DATA, SET_APP_LOADING } from "src/redux/constants";

export const setAppData = (payload) => async (dispatch) => {
  dispatch({
    type: SET_APP_DATA,
    payload: payload,
  });
};

export const setAppLoading = (payload) => async (dispatch) => {
  setTimeout(() => {
    dispatch({
      type: SET_APP_LOADING,
      payload,
    });
  }, 2100);
};
