import React, { useEffect, useState, useRef } from "react";
import {
  Spinner,
  Container,
  HStack,
  Center,
  Button,
  Input,
  VStack,
  Text,
  Box,
  Textarea,
  Image,
  CheckboxGroup,
  Checkbox,
} from "@chakra-ui/react";

//Api
import API from "src/config/axios";
import API_SLUGS from "src/config/url";

//style
import {
  ReactSelectComponent as ReactSelect,
  SwitchBtn,
  UploadBox,
} from "./style";

//components
import { useToastHook } from "src/components/toast";

const inputsConfig = {
  published: { label: "این مقاله قابل مشاهده باشد؟", type: "switch" },
  imgCollection: { label: "عکس خبر", type: "upload" },
  categorie: { label: "انتخاب دسته‌بندی", type: "select" },
  title: { label: "عنوان خبر", type: "input" },
  description: { label: "متن خبر", type: "textArea" },
};
const regions = [
  { label: "فا", value: "fa" },
  { label: "EN", value: "en" },
];

const AddBlog = ({ onClose, currentItem }) => {
  const editMode = currentItem && currentItem._id ? true : false;
  const inputRefs = useRef([]);
  const [toast, makeToast] = useToastHook();
  const [isFetching, setIsFetching] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categories, setCategories] = useState([]);
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    published: true,
    imgCollection: "",
    categorie: "",
    availableRegions: "",
  });

  useEffect(() => {
    API.get(API_SLUGS["categorie"])
      .then((response) => {
        let { data } = response;
        data = data.filter((item) => item.value !== "all");
        setCategories(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
    if (editMode) {
      let obj = {};
      const currentInputs = { ...inputs };
      Object.keys(currentInputs).map((key) => {
        switch (key) {
          case "imgCollection":
            obj[key] = currentItem[key][0];
            break;
          case "categorie":
            obj[key] = currentItem[key]
              ? { label: currentItem[key].label, value: currentItem[key]._id }
              : "";
            break;
          case "availableRegions":
            obj[key] = currentItem[key].length > 0 ? currentItem[key] : "";
            break;
          default:
            obj[key] = currentItem[key];
            break;
        }
      });
      setInputs(obj);
    }
  }, []);

  const onChangeUploadInput = async () => {
    const file = inputRefs.current["uploadFileInput"].files[0];
    const validTypes = ["image/jpeg", "image/png"];
    const fileTypeIsValid = validTypes.some((item) => file.type === item);
    const fileSizeIsValid = file.size < 1e6;
    if (fileTypeIsValid) {
      if (fileSizeIsValid) {
        setInputs({
          ...inputs,
          imgCollection: inputRefs.current["uploadFileInput"].files[0],
        });
      } else {
        makeToast({
          title: "حجم فایل آپلود شده بیش از ۱مگابایت می‌باشد.",
          status: "error",
        });
      }
    } else {
      makeToast({
        title: "فایل انتخابی باید .jpg یا .png باشد.",
        status: "error",
      });
    }
  };

  const onClickUploadBtn = () => {
    inputRefs.current["uploadFileInput"].click();
  };

  const onChangeInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const onClickSubmitBtn = async (event) => {
    event.preventDefault();
    const currentInputs = { ...inputs };
    const isValid = Object.values(currentInputs).every((item) => item !== "");
    if (isValid) {
      setIsFetching(true);
      const method = editMode ? "put" : "post";
      const url = editMode
        ? `${API_SLUGS["blog"]}/${currentItem._id}`
        : API_SLUGS["blog"];
      let title = "";
      let Status = "";
      let formData = new FormData();
      if (editMode) {
        title = "مقاله با موفقیت ویرایش شد";
        delete currentInputs.imgCollection;
        currentInputs.categorie = currentInputs.categorie.value;
        formData = currentInputs;
      } else {
        Object.keys(currentInputs).map((key) => {
          if (key === "categorie") {
            formData.append(key, currentInputs[key].value);
          } else {
            formData.append(key, currentInputs[key]);
          }
        });
      }
      await API[method](url, formData)
        .then((response) => {
          title = "مقاله با موفقیت افزوده شد";
          Status = "success";
        })
        .catch((err) => {
          const {
            response: {
              data: { msg },
            },
          } = err;
          title = msg;
          Status = "error";
        });
      makeToast({
        title,
        status: Status,
      });
      onClose();
      setIsFetching(false);
    } else {
      makeToast({ title: "تمامی فیلد‌ها باید پر باشد", status: "error" });
    }
  };

  return (
    <HStack spacing="0px">
      <Center w="100%" bg="#edf2f7" p="50px" borderRadius={"2xl"}>
        <Container centerContent>
          {!loading ? (
            <VStack
              p={"30px"}
              pb="40px"
              bg="white"
              borderRadius={"3xl"}
              w={"100%"}
              maxW="500px"
            >
              <form onSubmit={onClickSubmitBtn} className="full-width">
                {Object.keys(inputsConfig).map((key) => {
                  let renderInput;
                  switch (inputsConfig[key].type) {
                    case "input":
                      renderInput = (
                        <Input
                          id={key}
                          name={key}
                          onChange={onChangeInput}
                          value={inputs[key]}
                          placeholder={inputsConfig[key].label}
                          variant="primary"
                        />
                      );
                      break;
                    case "switch":
                      renderInput = (
                        <SwitchBtn
                          isDisabled={!editMode}
                          name={key}
                          isChecked={inputs[key]}
                          onChange={(event) =>
                            setInputs({
                              ...inputs,
                              [event.target.name]: event.target.checked,
                            })
                          }
                          size="lg"
                        />
                      );
                      break;
                    case "textArea":
                      renderInput = (
                        <Textarea
                          id={key}
                          name={key}
                          onChange={onChangeInput}
                          value={inputs[key]}
                          placeholder={inputsConfig[key].label}
                          variant="primary"
                          resize="none"
                        />
                      );
                      break;
                    case "upload":
                      renderInput = (
                        <Box mb={2}>
                          <input
                            hidden
                            id="xlxsFileInput"
                            type="file"
                            value={""}
                            onChange={(e) => onChangeUploadInput(e)}
                            ref={(el) =>
                              (inputRefs.current["uploadFileInput"] = el)
                            }
                          />
                          <UploadBox
                            htmlFor="xlxsFileInput"
                            disabled={editMode}
                            onClick={!editMode ? onClickUploadBtn : () => {}}
                            variant={"outline"}
                          >
                            {inputs[key] ? (
                              <Image
                                src={
                                  editMode
                                    ? inputs[key]
                                    : URL.createObjectURL(inputs[key])
                                }
                                width={"100%"}
                                display={"block"}
                                m={"0 auto"}
                              />
                            ) : (
                              <Text
                                textDecoration={"underline"}
                                color="#3182ce"
                              >
                                محل آپلود عکس
                              </Text>
                            )}
                          </UploadBox>
                        </Box>
                      );
                      break;
                    case "select":
                      renderInput = (
                        <ReactSelect
                          isDisabled={editMode}
                          options={categories}
                          placeholder={inputsConfig[key].label}
                          onChange={(event) =>
                            setInputs({
                              ...inputs,
                              categorie: {
                                label: event.label,
                                value: event._id,
                              },
                            })
                          }
                          value={inputs[key]}
                        />
                      );
                      break;
                    default:
                      break;
                  }
                  return (
                    <Box position="relative" mb={2}>
                      <Text
                        color="black"
                        fontSize={"md"}
                        textAlign={"right"}
                        lineHeight="10"
                      >
                        {inputsConfig[key].label}
                      </Text>
                      {renderInput}
                    </Box>
                  );
                })}
                <HStack justify={"space-between"} align="center" mt="30px">
                  <Text
                    color="black"
                    fontSize={"md"}
                    textAlign={"right"}
                    lineHeight="10"
                  >
                    انتشار در
                  </Text>
                  <Box>
                    <CheckboxGroup
                      name="availableRegions"
                      defaultValue={inputs.availableRegions}
                      onChange={(selectedRegions) =>
                        setInputs({
                          ...inputs,
                          availableRegions:
                            selectedRegions.length > 0 ? selectedRegions : "",
                        })
                      }
                    >
                      {regions.map((region) => {
                        return (
                          <Checkbox
                            size="md"
                            mx="10px"
                            value={region.value}
                            key={`${region.value}_checkbox`}
                          >
                            {region.label}
                          </Checkbox>
                        );
                      })}
                    </CheckboxGroup>
                  </Box>
                </HStack>
                <Input mt={10} size="xl" type="submit" hidden />
                <Button
                  mt={"50px"}
                  size="xl"
                  isLoading={isFetching}
                  onClick={onClickSubmitBtn}
                >
                  {editMode ? "ویرایش مقاله" : "افزودن مقاله"}
                </Button>
              </form>
            </VStack>
          ) : (
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          )}
        </Container>
      </Center>
    </HStack>
  );
};

export default AddBlog;
