import styled from "styled-components";
import Icon from "src/config/icon.js";
import { Table as ChakraUiTable, Text } from "@chakra-ui/react";

export const PaginationContainer = styled.div`
  width: 100%;
  background: white;
  position: absolute;
  bottom: 50px;
`;

export const Status = styled.p`
  color: white;
  display: block;
  margin: 0 auto;
  width: 65%;
  padding: 8px 0px;
  padding-top: 11px;
  border-radius: 999px;
  &.waiting {
    background-color: #9e9e9e;
  }
  &.paid {
    background-color: #48a34c;
  }
  &.sent {
    background-color: #344bb1;
  }
`;

export const Table = styled(ChakraUiTable)`
  display: flex;
  flex-flow: column;
  width: 100%;
  overflow: scroll;
  & thead {
    flex: 0 0 auto;
    background: #dceaf7;
    padding: 15px 10px;
  }
  & tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
  }

  tbody tr:hover {
    /* background: #005087; */
    /* color: white; */
  }
  tbody tr:nth-of-type(odd):hover {
    /* background: #005087 !important;
    color: white; */
  }

  tr {
    width: 100%;
    display: table;
    table-layout: fixed;
    position: relative;
    transition: 0.2s;
    &.ongoing-proccess {
      background: #ffdb72;
    }
  }
  tbody tr:nth-of-type(odd) {
    background: #edf2f7;
    &.ongoing-proccess {
      background: #ffdb72;
    }
  }

  td {
    text-align: center;
    padding: 13px 0px;
    padding-top: 15px;
    border-bottom: 0px;
  }
  th {
    border: none;
    text-align: center;
    padding: 0px;
    letter-spacing: 0px;
    position: relative;
    .custom-table-header {
      margin: 0px auto;
    }
    .custom-table-sort-button {
      color: white;
      border-radius: 8px;
      width: 90%;
      display: block;
      margin: 0 auto;
      height: 40px;
      background: #005087;
      transition: 0.3s;
      :hover {
        background: #0565a7;
      }
    }
    .custom-table-sort-icon {
      position: absolute;
      top: 0px;
      left: 0px;
    }
  }
`;

export const PhoneNumber = styled(Text)``;

export const PaginationBtn = styled(Text)`
  cursor: pointer;
`;

export const UpdateIcon = styled(Icon)`
  color: #669ccf;
  margin: 0px 15px;
  transition: 0.3s;
  cursor: pointer;
  :hover {
    transform: scale(1.3);
  }
`;

export const SortIcon = styled(Icon)`
  color: #333;
  position: absolute;
  right: 0px;
  top: 0px;
  transition: 0.3s;
  :hover {
    transform: scale(1.5);
  }
`;

export const TableIcon = styled(Icon)`
  &.check-icon {
    color: #18a418;
  }
  &.cross-icon {
    color: #e53e3e;
  }
`;

export const ArrowIcon = styled(Icon).attrs(() => ({
  size: 24,
}))`
  cursor: pointer;
`;
