import { extendTheme } from "@chakra-ui/react";
const langIsEn = window.location.pathname.slice(1).split("/")[0] === "en";

const theme = extendTheme({
  // initialColorMode: "light",
  // useSystemColorMode: false,
  direction: "rtl",
  colors: {
    white: "#ffffff",
    black: "#333",
    primary: {
      50: "#ffe1e6",
      100: "#ffb1b9",
      200: "#ff7f8b",
      300: "#ff4c5e",
      400: "#ff1a30",
      500: "#e60016",
      600: "#b40010",
      700: "#81000a",
      800: "#500004",
      900: "#210000",
    },
    secondary: {
      50: "#dcf6ff",
      100: "#b0e2ff",
      200: "#80cfff",
      300: "#51bcfe",
      400: "#29a9fc",
      500: "#005b92",
      600: "#0b6fb2",
      700: "#004f80",
      800: "#00304f",
      900: "#00111f",
    },
    gray: {
      50: "#eeeeee",
      100: "#d9d9d9",
      200: "#bfbfbf",
      300: "#a6a6a6",
      400: "#8c8c8c",
      500: "#737373",
      600: "#595959",
      700: "#404040",
      800: "#262626",
      900: "#120b0d",
    },
  },
  breakpoints: {
    sm: "320px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  },
  fonts: {
    heading: langIsEn ? "Poppins-Bold" : "Vazir-Bold",
    body: langIsEn ? "Poppins-Regular" : "Vazir-Regular",
  },
  components: {
    Container: {
      baseStyle: {
        maxW: {
          sm: "90%",
          md: "90%",
          "2xl": "96em",
        },
      },
    },
    Heading: {
      baseStyle: {
        baseStyle: {
          fontSize: "xl",
        },
      },
    },
    Text: {
      baseStyle: {
        fontSize: "lg",
        fontWeight: 200,
        lineHeight: "32px",
      },
      variants: {
        breadcrump: {
          color: "black",
        },
      },
    },
    Select: {
      variants: {
        primary: {
          field: {
            _placeholder: { color: "gray.500" },
            bg: "gray.200",
            color: "black",
            height: "60px",
            textAlign: "center",
            borderRadius: "xl",
          },
        },
      },
    },
    Select: {
      variants: {
        primary: {
          field: {
            _placeholder: { color: "gray.400" },
            bg: "#F3F6FE",
            color: "black",
            height: "70px",
            textAlign: "center",
            borderRadius: "xl",
            _disabled: {
              opacity: 0.5,
            },
          },
        },
      },
    },
    Textarea: {
      variants: {
        primary: {
          _placeholder: { color: "gray.400" },
          bg: "#F3F6FE",
          color: "black",
          height: "200px",
          padding: "20px",
          textAlign: "center",
          borderRadius: "xl",
          _disabled: {
            opacity: 0.5,
          },
        },
      },
    },
    Input: {
      variants: {
        primary: {
          field: {
            _placeholder: { color: "gray.400" },
            bg: "#F3F6FE",
            color: "black",
            height: "70px",
            textAlign: "center",
            borderRadius: "xl",
            _disabled: {
              opacity: 0.5,
            },
          },
        },
      },
    },
    Button: {
      defaultProps: {
        colorScheme: "secondary",
        backgroundColor: "secondary.500",
      },
      baseStyle: {
        w: "100%",
        borderRadius: "xl",
        color: "white",
        borderColor: "secondary.500",
      },
      sizes: {
        xl: {
          h: "70px",
          fontSize: "lg",
        },
        lg: {
          h: "60px",
          fontSize: "md",
        },
        md: {
          h: "60px",
          fontSize: "md",
        },
        sm: {
          h: "40px",
          fontSize: "sm",
          borderRadius: "lg",
        },
        xs: {
          h: "40px",
          fontSize: "xs",
          borderRadius: "lg",
        },
      },
      variants: {
        float: {
          w: "60px",
          h: "60px",
          bg: "secondary.500",
          position: "absolute",
          right: "15px",
          bottom: "80px",
          borderRadius: "full",
        },
        "input-header-btn": {
          w: "70px",
          h: "30px",
          bg: "secondary.500",
          fontSize: "sm",
          borderRadius: "999px",
        },
        outline: {
          w: "100%",
          h: "55px",
          bg: "transparent",
          fontSize: "sm",
          borderRadius: "xl",
          borderWidth: "1px",
          borderColor: "secondary.500",
          borderStyle: "dashed",
        },
      },
    },
  },
});

export default theme;
