import { applyMiddleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import reducer from "src/redux/reducers";

const initalState = {};
const middleware = [thunk];

const composeEnhancers = composeWithDevTools({});
const store = configureStore({
  reducer,
  preloadedState: initalState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
composeEnhancers(applyMiddleware(...middleware));

export default store;
