const config = {
  axiosBaseUrl: process.env.REACT_APP_URL,
  social: ["whatsapp", "linkedin", "instagram"],
  whatsapp: "https://api.whatsapp.com/send?phone=+989399303509",
  locale: "fa",
  sliderDelay: 3500,
  menu: [
    {
      name: { fa: "صفحه اصلی", en: "Home" },
      ref: "home",
      type: "link",
    },
    {
      name: { fa: "فعالیت", en: "Activity" },
      ref: "activity",
      type: "subMenu",
      child: [
        {
          name: { fa: "محصولات", en: "Products" },
          ref: "products",
          type: "scroll",
        },
        {
          name: { fa: "خدمات", en: "Services" },
          ref: "services",
          type: "scroll",
        },
      ],
    },
    {
      name: { fa: "همکاری باما", en: "Cooperation" },
      ref: "cooperation",
      type: "subMenu",
      child: [
        {
          name: { fa: "پیمانکار", en: "contractor" },
          ref: "contractor",
          type: "link",
        },
        {
          name: { fa: "استخدام", en: "Employment" },
          ref: "employment",
          type: "link",
        },
        {
          name: { fa: "مشتری", en: "Customer orders" },
          ref: "order",
          type: "link",
        },
      ],
    },
    {
      name: { fa: "درباره‌ما", en: "About Us" },
      ref: "about-us",
      type: "scroll",
    },
    {
      name: { fa: "اخبار و مقالات", en: "News" },
      ref: "blogs/all",
      type: "scroll",
    },
    {
      name: { fa: "تماس باما", en: "Contact Us" },
      ref: "contact-us",
      type: "scroll",
    },
  ],
  dashboardTabs: {
    admin: {
      blog: {
        title: "اخبار و مقالات",
        slug: "news",
        icon: "clipboard",
      },
      categorie: {
        title: "دسته‌‌بندی ها",
        slug: "categories",
        icon: "categories",
      },
      user: {
        title: "کاربران",
        slug: "users",
        icon: "user",
      },
    },
  },
};

export default config;
