import React from "react";
import { Flex, Box, Container, Image, Heading } from "@chakra-ui/react";
import {} from "./style";
import { IMAGES } from "src/images";

const Loader = () => {
  return (
    <Box
      flex={1}
      flexDirection="column"
      justify="center"
      align="center"
      w="100%"
      h="100vh"
      className="dark-bg"
    >
      <Box className="loader-Box">
        <Container className="router-stack-container">
          <Image
            src={IMAGES.logo}
            width={"100px"}
            display={"block"}
            m={"0 auto"}
          />
          <Box className="typer-container" mt="30px">
            <Heading
              className="typer"
              color={"white"}
              dir="ltr"
              fontFamily={"Poppins-Bold"}
            >
              Dana Machine...
            </Heading>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Loader;
