export const tablesObject = {
  blog: {
    index: "ردیف",
    title: "عنوان",
    categorie: "دسته بندی",
    availableRegions: "انتشار در",
    published: "وضعیت مشاهده",
  },
  categorie: {
    index: "ردیف",
    label: "عنوان",
    value: "آدرس",
    posts: "تعداد پست",
  },
  user: {
    index: "ردیف",
    fullName: "نام و نام خانوادگی",
    username: "نام کاربری",
    role: "سطح دسترسی",
  },
};

export const tablesArray = (key) => {
  let array;
  return (array = Object.entries(tablesObject[key]).map((item) => {
    return { accessor: item[0], Header: item[1] };
  }));
};
