import React from "react";
import { Routes, Route, Navigate, useOutletContext } from "react-router-dom";
import pages from "./pages";

//components
import Loader from "src/components/loader";

//loadable
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";

const LoadablePage = loadable(
  ({ page }) => pMinDelay(import(`src/pages/${page}`), 1600),
  {
    cacheKey: (props) => props.page,
  }
);
const getIndexRoute = pages.filter((route) => route.index === true);
export const indexRoute = getIndexRoute ? getIndexRoute[0] : pages[0];

const RouterStack = () => {
  const [location, { localeIsEn }] = useOutletContext();
  return (
    <Routes>
      <Route path="/" element={<Navigate to={indexRoute.url} />} />
      {pages.map(({ slug, url, index }) => {
        return (
          <Route
            key={`route_${slug}`}
            path={url}
            element={
              <LoadablePage
                fallback={<Loader />}
                page={slug}
                locale={location}
                localeIsEn={localeIsEn}
              />
            }
          />
        );
      })}
    </Routes>
  );
};

export default RouterStack;
