import jwt_decode from "jwt-decode";
import setAuthToken from "src/config/axios/setAuthToken";
import API from "src/config/axios";
import API_SLUGS from "src/config/url";
import {
  SET_CURRENT_USER,
  LOG_OUT_USER,
  SET_USER_ERROR,
  SET_USER_LOADING,
  SET_APP_LOADING,
  SET_USER_DATA,
} from "src/redux/constants";

export const setCurrentUser =
  (token = {}) =>
  async (dispatch) => {
    setAuthToken(token);
    const decoded = jwt_decode(token);
    try {
      const response = await API.get(`users/${decoded.id}`);
      const userInfo = response.data;
      localStorage.setItem("userToken", token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: userInfo,
      });
      setTimeout(() => {
        dispatch({
          type: SET_APP_LOADING,
          payload: false,
        });
      }, 1000);
    } catch (err) {
      if (
        err.response.statusText == "Unauthorized" ||
        err.response.status == 401
      ) {
        dispatch(logoutUser());
      }
      dispatch({
        type: SET_USER_ERROR,
        payload: err.response,
      });
      dispatch({
        type: SET_APP_LOADING,
        payload: false,
      });
    }
  };

export const registerUser = (data) => async (dispatch) => {
  try {
    const response = await API.post(API_SLUGS.register, data);
    dispatch({
      type: SET_USER_DATA,
      payload: response,
    });
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
  }
};

export const loginUser = (data) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_LOADING,
      payload: true,
    });
    const response = await API.post(API_SLUGS.login, data);
    const { token } = response.data;
    dispatch(setCurrentUser(token));
  } catch (err) {
    dispatch({
      type: SET_USER_ERROR,
      payload: err.response,
    });
    return Promise.reject(err.response.data);
  }
};

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem("userToken");
  setAuthToken(false);
  dispatch({
    type: LOG_OUT_USER,
  });
};
