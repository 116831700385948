import { ColorModeScript } from "@chakra-ui/react";
import React, { StrictMode } from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import "src/styles/index.css";
import "src/styles/pattern.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import "leaflet/dist/leaflet.css";
import { BrowserRouter as Router } from "react-router-dom";

//components
import App from "./app";
import { ChakraProvider } from "@chakra-ui/react";
// import theme from "src/theme/theme";
import theme from "src/styles/chakra-ui/theme";

//redux
import { Provider } from "react-redux";
import store from "src/redux/store";

ReactDOM.render(
  <Provider store={store}>
    <StrictMode>
      <ChakraProvider theme={theme} resetCss={false}>
        <Router>
          <App />
        </Router>
      </ChakraProvider>
    </StrictMode>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
