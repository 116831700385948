import React, { useEffect, useState, useRef } from "react";
import {
  VStack,
  useDisclosure,
  Flex,
  Heading,
  Tag,
  Spinner,
  Box,
  Image,
  Button,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { BtnIcon as Icon } from "./style";
import { tablesArray } from "src/config/table";
import { IMAGES } from "src/images";
import { useToastHook } from "src/components/toast";

//Api
import API from "src/config/axios";
import API_SLUGS from "src/config/url";

//components
import DataTable from "src/components/data-table";
import Modal from "src/components/modal";
import AddCategories from "./add-categorie";

const Cats = () => {
  //modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  //alertDialoge
  const {
    isOpen: alertIsOpen,
    onOpen: alertOnOpen,
    onClose: alertOnClose,
  } = useDisclosure();
  const cancelRef = useRef();

  const [data, setData] = useState([]);
  const [newData, setNewData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const [toast, makeToast] = useToastHook();

  const columns = tablesArray("categorie");

  useEffect(() => {
    setLoading(true);
    API.get(API_SLUGS["categorie"])
      .then((response) => {
        let { data } = response;
        data = data.filter((item) => item.value !== "all");
        data.map((item, index) => {
          item.index = index + 1;
        });
        setData(data);
        setLoading(false);
        setBtnLoading(false);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }, [newData]);

  const onClickTableRow = (item, type) => {
    switch (type) {
      case "edit":
        setCurrentItem(item);
        onOpen(true);
        break;
      case "trash":
        setCurrentItem(item);
        alertOnOpen(true);
        break;
      default:
        break;
    }
  };

  const onClickRemove = async () => {
    setBtnLoading(true);
    let title = "";
    let Status = "";
    let response = await API.delete(
      `${API_SLUGS.categorie}/${currentItem._id}`
    );
    const { status } = response;
    if (status == 200) {
      title = "پست مورد نظر با موفقیت حذف شد";
      Status = "success";
    }
    if (status == 400) {
      title = "پست مورد نظر یافت نشد";
      Status = "error";
    }
    makeToast({
      title,
      status: Status,
    });
    alertOnClose(true);
    setNewData((prevState) => !prevState);
  };

  const modalTitle =
    currentItem && currentItem.id
      ? `ویرایش پست "${currentItem.fullName}"`
      : "افزودن پست";

  return (
    <VStack
      dir="rtl"
      alignItems="center"
      justify={"center"}
      p="30px"
      w={"100%"}
      h={"100%"}
    >
      {loading ? (
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      ) : data.length !== 0 ? (
        <Box w={"100%"} h={"100%"}>
          <Flex
            alignItems="center"
            justify={"space-between"}
            flexDir="row"
            w={"100%"}
            mb={"15px"}
          >
            <Heading
              size="lg"
              display={"flex"}
              justifyContent="center"
              alignItems={"center"}
            >
              لیست دسته‌بندی ها <Tag mr="10px">{data.length} دسته‌بندی</Tag>
            </Heading>
            <Button
              size="sm"
              w="100px"
              isLoading={false}
              rightIcon={<Icon icon={"add"} size={"16px"} />}
              onClick={() => {
                setCurrentItem(null);
                onOpen(true);
              }}
            >
              افزودن
            </Button>
          </Flex>
          <DataTable
            columns={columns}
            tableData={data || []}
            onClickTableItem={onClickTableRow}
            className="custom-table-history"
            tableName={"categorie"}
          />
        </Box>
      ) : (
        <Flex w={"100%"} justify="center" align={"center"}>
          <Image
            src={IMAGES.empty2}
            width={"50%"}
            display={"block"}
            m={"0 auto"}
            cursor="pointer"
            onClick={() => {
              setCurrentItem(null);
              onOpen(true);
            }}
          />
        </Flex>
      )}
      <Modal isOpen={isOpen} onClose={onClose} title={modalTitle}>
        <AddCategories
          currentItem={currentItem}
          onClose={() => {
            onClose(true);
            setNewData((prevState) => !prevState);
          }}
        />
      </Modal>
      <AlertDialog
        isOpen={alertIsOpen}
        leastDestructiveRef={cancelRef}
        onClose={alertOnClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              آیا از حذف پست "{currentItem && currentItem.title}" اطمینان دارید؟
            </AlertDialogHeader>
            <AlertDialogFooter spacing="1">
              <Button
                colorScheme="red"
                ml={3}
                size="sm"
                isLoading={btnLoading}
                onClick={onClickRemove}
              >
                حذف
              </Button>
              <Button
                colorScheme="gray"
                ref={cancelRef}
                size="sm"
                onClick={alertOnClose}
                color="#333"
              >
                لغو
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </VStack>
  );
};

export default Cats;
