import {
  CREATE_DASHBOARD_TABS,
  SET_DASHBOARD_LOADING,
  MODIFY_DASHBOARD_TABS,
} from "src/redux/constants";

const initialState = {
  error: [],
  loading: false,
};

export default function (state = initialState, action) {
  const storedData = { ...state };
  const payload = action.payload;
  switch (action.type) {
    case CREATE_DASHBOARD_TABS:
      storedData[payload.tab] = {
        steps: payload.data,
      };
      return storedData;
    case MODIFY_DASHBOARD_TABS:
      storedData[payload.tab].steps[payload.step].validation =
        payload.validation;
      return storedData;
    case SET_DASHBOARD_LOADING:
      storedData.loading = payload;
      return storedData;
    default:
      return state;
  }
}
