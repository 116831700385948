const pages = [
  { slug: "home", url: "home", index: true },
  { slug: "contact-us", url: "contact-us", index: false },
  { slug: "about-us", url: "about-us", index: false },
  { slug: "cooperation", url: "cooperation/:type", index: false },
  { slug: "blog", url: "blog/:id", index: false },
  { slug: "blogs", url: "blogs/:categorie", index: false },
  { slug: "product-single", url: "products/:cat/:subCat", index: false },
  { slug: "services", url: "activity/services", index: false },
  { slug: "products", url: "activity/products", index: false },
  // { slug: "qr-code", url: "qrcode/generator", index: false },
];

export default pages;
