import React, { useEffect, useState } from "react";
import {
  Container,
  HStack,
  Center,
  Button,
  Input,
  VStack,
  Text,
  Image,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useToastHook } from "src/components/toast";
import { IMAGES } from "src/images";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loginUser } from "src/redux/user/actions";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toast, makeToast] = useToastHook();
  const user = useSelector((state) => state.user);
  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    const token = localStorage.getItem("userToken");
    if (token) {
      navigate("/dashboard");
    }
  }, [user.isFetching]);

  const onChangeInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const onClickSubmitBtn = (event) => {
    const { username, password } = inputs;
    event.preventDefault();
    if (username && password) {
      dispatch(loginUser(inputs))
        .then((res) => {})
        .catch((err) => {
          makeToast({
            title: err.message || "مشکلی در ارتباط با سرور وجود دارد",
            status: "error",
          });
        });
    } else {
      makeToast({ title: "تمامی فیلد‌ها باید پر باشد", status: "error" });
    }
  };

  return (
    <HStack spacing="0px">
      <Center w="100%" h="100vh" bg="#edf2f7">
        <Container centerContent>
          <VStack p={"20px"} pb="40px" bg="white" borderRadius={"3xl"}>
            <Image
              src={IMAGES.logo}
              width={"25%"}
              display={"block"}
              m={"0 auto"}
            />
            <form onSubmit={onClickSubmitBtn}>
              <Text
                color="black"
                fontSize={"md"}
                textAlign={"right"}
                lineHeight="10"
              >
                نام کاربری
              </Text>
              <Input
                id="username"
                name="username"
                placeholder="نام کاربری"
                variant="primary"
                onChange={onChangeInput}
                value={inputs.username}
                mb={2}
              />
              <Text
                color="black"
                fontSize={"md"}
                textAlign={"right"}
                lineHeight="10"
              >
                کلمه عبور
              </Text>
              <Input
                id="password"
                name="password"
                placeholder="کلمه عبور"
                type="password"
                variant="primary"
                onChange={onChangeInput}
                value={inputs.password}
              />
              <Input mt={10} size="xl" type="submit" hidden />
              <Button
                mt={"20px"}
                size="xl"
                isLoading={user.isFetching}
                onClick={onClickSubmitBtn}
              >
                ورود به پنل
              </Button>
            </form>
          </VStack>
        </Container>
      </Center>
    </HStack>
  );
};

export default LoginPage;
