import React, { useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";

//router
import RouterStack, { indexRoute } from "./router";

//redux
import { useDispatch, useSelector } from "react-redux";
import { setAppData } from "src/redux/app/actions";
import { setCurrentUser } from "src/redux/user/actions";

//pages
import ProtectedRoute from "src/router/protected-route";
import DashboardPage from "src/pages/dashboard";
import LoginPage from "src/pages/login";

//components
import Blogs from "src/components/dashboard/tabs/blogs";
import Users from "src/components/dashboard/tabs/users";
import Categories from "src/components/dashboard/tabs/categories";

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const app = useSelector((state) => state.app);

  useEffect(async () => {
    const token = localStorage.getItem("userToken");
    const currentLocation = location.pathname;
    const currentLocationIsTarget =
      currentLocation.includes("dashboard") ||
      currentLocation.includes("login");
    if (token && currentLocationIsTarget) {
      dispatch(setCurrentUser(token));
    }
  }, []);

  const setLocale = (newLocale) => {
    localStorage.setItem("locale", newLocale);
    dispatch(setAppData({ locale: newLocale }));
  };

  return (
    <Routes>
      <Route
        path="/"
        element={<Navigate to={`${app.locale}/${indexRoute.url}`} />}
      />
      <Route path="/en/*" element={<Root setLocale={setLocale} />}>
        <Route path="*" element={<RouterStack locale={app.locale} />} />
      </Route>
      <Route path="/fa/*" element={<Root setLocale={setLocale} />}>
        <Route path="*" element={<RouterStack locale={app.locale} />} />
      </Route>
      <Route
        path={"dashboard"}
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      >
        <Route index element={<Navigate to={"news"} />} />
        <Route
          path="news"
          element={
            <ProtectedRoute>
              <Blogs />
            </ProtectedRoute>
          }
        />
        <Route
          path="users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="categories"
          element={
            <ProtectedRoute>
              <Categories />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<p>no match</p>} />
      </Route>
      <Route path={"/login"} element={<LoginPage />} />
      <Route path="*" element={<p>error page</p>} />
    </Routes>
  );
};

const Root = ({ setLocale }) => {
  const location = useLocation();
  let locale = localStorage.getItem("locale");
  let parsedLocale = location.pathname.substring(1, 3);
  const localeIsEn = parsedLocale === "en";
  if (locale !== parsedLocale) {
    setLocale(parsedLocale);
    locale = parsedLocale;
  }
  return <Outlet context={[parsedLocale, { localeIsEn }]} />;
};

export default App;
