import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
import _ from "underscore";
import {
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Flex,
  Text,
  Input,
  Box,
} from "@chakra-ui/react";
import {
  ArrowIcon,
  SortIcon,
  UpdateIcon,
  TableIcon as Icon,
  Table,
  PaginationContainer,
} from "./style";
import { toFarsiNumber } from "src/helper";

const CustomTable = ({ columns, tableData, onClickTableItem, tableName }) => {
  const dashboard = useSelector((state) => state.dashboard);
  const currentData = [...tableData];
  const [data, setData] = useState(currentData);

  const GlobalFilter = ({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) => {
    const count = preGlobalFilteredRows.length;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 200);

    return (
      <Input
        variant={"primary"}
        mt={"10px"}
        mb={"15px"}
        bg="#dceaf7"
        className="form-control"
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`از میان ${count} داده جستجو کنید...`}
      />
    );
  };

  const DefaultColumnFilter = ({
    column: { filterValue, preFilteredRows, setFilter },
  }) => {
    return (
      <Input
        variant={"primary"}
        bg="white"
        height={"40px"}
        borderRadius={"8px"}
        fontSize={"12px"}
        w={"90%"}
        className="form-control"
        value={filterValue || ""}
        mt={"10px"}
        onChange={(e) => {
          setFilter(e.target.value || undefined);
        }}
        placeholder={"جستجو کنید..."}
      />
    );
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    nextPage,
    previousPage,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: { pageIndex: 0, pageSize: 13 },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    return () => {
      if (currentData !== tableData) {
        const newRecord = tableData.filter(
          ({ _id: id1 }) => !currentData.some(({ _id: id2 }) => id2 === id1)
        );
        setData([...currentData, ...newRecord]);
      }
    };
  }, [dashboard]);

  const onClickTableRow = (item, type) => (event) => {
    event.preventDefault();
    if (onClickTableItem) {
      onClickTableItem(item, type);
    }
  };

  return (
    <Box w="100%" h="100%" position={"relative"}>
      <GlobalFilter
        preGlobalFilteredRows={preGlobalFilteredRows}
        globalFilter={state.globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <Table
        {...getTableProps()}
        size="sm"
        className="custom-table"
        borderRadius={"xl"}
        boxShadow={"md"}
      >
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr
              {...headerGroup.getHeaderGroupProps()}
              key={`header_group_${index}`}
            >
              {headerGroup.headers.map((column, index) => {
                let sortIcon = "sort3";
                if (column.isSortedDesc !== undefined) {
                  if (column.isSortedDesc == true) {
                    sortIcon = "arrow-down";
                  } else {
                    sortIcon = "arrow-up";
                  }
                }

                return (
                  <Th key={`th_${index}`}>
                    <Text
                      {...column.getHeaderProps()}
                      noOfLines="1"
                      mb="30px"
                      className="custom-table-header"
                    >
                      <SortIcon
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                        size="16px"
                        icon={sortIcon}
                      />
                      {column.render("Header")}
                    </Text>
                    <div>
                      {column.canFilter ? column.render("Filter") : null}
                    </div>
                  </Th>
                );
              })}
              <Th>ویرایش/حذف</Th>
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            const isOngoing =
              row.original.type === "calc-history" && row.original.STATUS === 0;
            return (
              <Tr
                key={`tr_${index}`}
                {...row.getRowProps()}
                // cursor={onClickTableItem && "pointer"}
                // onClick={onClickTableRow(row.original, "body")}
                className={isOngoing && "ongoing-proccess"}
              >
                {row.cells.map((cell, index) => {
                  const key = cell.column.id;
                  let value = cell.render("Cell");

                  if (tableName === "blog") {
                    switch (key) {
                      case "published":
                        const published = cell.value == true;
                        value = (
                          <Icon
                            className={published ? "check-icon" : "cross-icon"}
                            icon={published ? "check2" : "cross"}
                            size={published ? 22 : 18}
                          />
                        );
                        break;
                      case "categorie":
                        if (cell.value) {
                          value = cell.value.label;
                        } else {
                          value = "مشخص نشده";
                        }
                        break;
                      case "availableRegions":
                        value = cell.value.map((item) => {
                          return `_${item}`;
                        });
                        break;
                      default:
                        value = cell.render("Cell");
                        break;
                    }
                  }
                  if (tableName === "categorie") {
                    switch (key) {
                      case "posts":
                        value = (
                          <Text
                            fontSize={"sm"}
                            {...cell.getCellProps()}
                            textAlign="center"
                          >
                            {cell.value.length}
                          </Text>
                        );
                        break;
                      default:
                        value = cell.render("Cell");
                        break;
                    }
                  }
                  return (
                    <Td
                      fontSize={"sm"}
                      {...cell.getCellProps()}
                      key={`td_${index}`}
                    >
                      {value}
                    </Td>
                  );
                })}
                <Td fontSize={"sm"}>
                  <UpdateIcon
                    size="20px"
                    icon={"edit"}
                    onClick={onClickTableRow(row.original, "edit")}
                  />
                  <UpdateIcon
                    size="22px"
                    icon={"trash3"}
                    onClick={onClickTableRow(row.original, "trash")}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {pageOptions.length > 1 && (
        <PaginationContainer>
          <Flex justifyContent="space-between" m={4} alignItems="center">
            <Flex>
              <ArrowIcon onClick={previousPage} icon={"arrow-right"} />
            </Flex>
            <Flex alignItems="center">
              <Text mx={8}>
                صفحه {toFarsiNumber(pageIndex + 1)} از{" "}
                {toFarsiNumber(pageOptions.length)}
              </Text>
            </Flex>
            <Flex>
              <ArrowIcon onClick={nextPage} icon={"arrow-left"} />
            </Flex>
          </Flex>
        </PaginationContainer>
      )}
    </Box>
  );
};

export default CustomTable;
