import styled from "styled-components";
import Icon from "src/config/icon";

export const MenuIcon = styled(Icon).attrs(() => ({}))`
  margin-left: 15px;
  color: ${(props) => (props.active === "true" ? "#344cb1" : "black")};
  &.vertical-align {
    margin-left: 10px;
    vertical-align: middle;
  }
`;

export const BtnIcon = styled(Icon).attrs(() => ({}))`
  margin-left: 15px;
  color: "#344cb1";
`;
