import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  HStack,
  Center,
  Button,
  Input,
  VStack,
  Text,
  Box,
  Textarea,
} from "@chakra-ui/react";
import { useToastHook } from "src/components/toast";

//Api
import API from "src/config/axios";
import API_SLUGS from "src/config/url";

const inputsConfig = {
  label: { label: "عنوان", type: "input" },
  value: { label: "آدرس لینک", type: "input" },
};

const AddCats = ({ onClose, currentItem }) => {
  const editMode = currentItem && currentItem._id ? true : false;
  const inputRefs = useRef([]);
  const [toast, makeToast] = useToastHook();
  const [isFetching, setIsFetching] = useState(false);
  const [inputs, setInputs] = useState({
    label: "",
    value: "",
  });

  useEffect(() => {
    if (editMode) {
      let obj = {};
      const currentInputs = { ...inputs };
      Object.keys(currentInputs).map((key) => {
        obj[key] = currentItem[key];
      });
      setInputs(obj);
    }
  }, []);

  const onChangeInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const onClickSubmitBtn = async (event) => {
    event.preventDefault();
    const currentInputs = { ...inputs };
    const isValid = Object.values(currentInputs).every((item) => item !== "");
    if (isValid) {
      setIsFetching(true);
      const method = editMode ? "put" : "post";
      const url = editMode
        ? `${API_SLUGS["categorie"]}/${currentItem._id}`
        : API_SLUGS["categorie"];
      let title = "دسته‌بندی با موفقیت افزوده شد";
      let status = "success";
      let formData = currentInputs;
      if (editMode) {
        title = "دسته‌بندی با موفقیت ویرایش شد";
      }
      formData.value = formData.value.toLowerCase();
      await API[method](url, formData).catch((err) => {
        const {
          response: {
            data: { msg },
          },
        } = err;
        title = msg;
        status = "error";
      });
      makeToast({
        title,
        status,
      });
      onClose();
      setIsFetching(false);
    } else {
      makeToast({ title: "تمامی فیلد‌ها باید پر باشد", status: "error" });
    }
  };

  return (
    <HStack spacing="0px">
      <Center w="100%" bg="#edf2f7" p="50px" borderRadius={"2xl"}>
        <Container centerContent>
          <VStack
            p={"30px"}
            pb="40px"
            bg="white"
            borderRadius={"3xl"}
            w={"100%"}
            maxW="500px"
          >
            <form onSubmit={onClickSubmitBtn} className="full-width">
              {Object.keys(inputsConfig).map((key) => {
                let renderInput;
                switch (inputsConfig[key].type) {
                  case "input":
                    renderInput = (
                      <Input
                        id={key}
                        name={key}
                        onChange={onChangeInput}
                        value={inputs[key]}
                        placeholder={inputsConfig[key].label}
                        variant="primary"
                      />
                    );
                    break;
                  case "textArea":
                    renderInput = (
                      <Textarea
                        id={key}
                        name={key}
                        onChange={onChangeInput}
                        value={inputs[key]}
                        placeholder={inputsConfig[key].label}
                        variant="primary"
                        resize="none"
                      />
                    );
                    break;
                  default:
                    break;
                }
                return (
                  <Box position="relative" mb={2}>
                    <Text
                      color="black"
                      fontSize={"md"}
                      textAlign={"right"}
                      lineHeight="10"
                    >
                      {inputsConfig[key].label}
                    </Text>
                    {renderInput}
                  </Box>
                );
              })}
              <Input mt={10} size="xl" type="submit" hidden />
              <Button
                mt={"50px"}
                size="xl"
                isLoading={isFetching}
                onClick={onClickSubmitBtn}
              >
                {editMode ? "ویرایش دسته‌بندی" : "افزودن دسته‌بندی"}
              </Button>
            </form>
          </VStack>
        </Container>
      </Center>
    </HStack>
  );
};

export default AddCats;
