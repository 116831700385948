import React, { useEffect, useState } from "react";
import { Image, Flex, WrapItem, Button } from "@chakra-ui/react";
import {
  useNavigate,
  useResolvedPath,
  useLocation,
  NavLink,
} from "react-router-dom";
import _ from "underscore";

//redux
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "src/redux/user/actions";

//components
import { MenuIcon, BtnIcon } from "./style";
import { IMAGES } from "src/images";
import config from "src/config";

const Navbar = () => {
  const user = useSelector((state) => state.user);
  const origin = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [navbar, setNavbar] = useState({});

  let resolved = useResolvedPath(origin);

  const onClickMenuItem = (item) => () => {
    navigate(`/dashboard/${item.slug}`, { replace: true });
  };

  useEffect(() => {
    let navbarItems = { ...config.dashboardTabs.admin };
    if (user.isAuthenticated) {
      if (user.data.role !== "admin") {
        navbarItems = _.omit(navbarItems, "user");
      }
      setNavbar(navbarItems);
    }
  }, [user]);

  return (
    <Flex width={"100%"} flexDir="column">
      <Flex flexDir={"column"} justify={"center"} align="center" py="30px">
        <NavLink to="/">
          <Image
            src={IMAGES.logo}
            width={"75%"}
            display={"block"}
            m={"0 auto"}
          />
        </NavLink>
      </Flex>
      <Flex
        direction={"column"}
        align="center"
        borderWidth={"1px 0px 1px 0px"}
        borderStyle="solid"
        borderColor="#e2e8f0"
        py={"10px"}
        px={"25px"}
        mb={"35px"}
      >
        {user.isAuthenticated === true &&
          Object.keys(navbar).length > 0 &&
          Object.values(navbar).map((nav, index) => {
            const activeLink = resolved.pathname === `/dashboard/${nav.slug}`;
            return (
              <Flex
                w={"100%"}
                align={"center"}
                key={`dashboard_menu_${index}`}
                onClick={onClickMenuItem(nav)}
                py={"20px"}
              >
                <MenuIcon
                  icon={nav.icon}
                  size={"26px"}
                  active={activeLink === true ? "true" : "false"}
                />
                <WrapItem
                  color={activeLink ? "#344cb1" : "black"}
                  fontSize={"md"}
                  textAlign={"center"}
                  width={"100%"}
                  borderRadius="2xl"
                  cursor={"pointer"}
                >
                  {nav.title}
                </WrapItem>
              </Flex>
            );
          })}
      </Flex>
      <Button
        variant="outline"
        w="85%"
        display={"flex"}
        margin={"0 auto"}
        mb={"35px"}
        onClick={() => dispatch(logoutUser())}
      >
        <BtnIcon icon={"logout"} size={"20px"} />
        خروج از حساب کاربری
      </Button>
    </Flex>
  );
};

export default Navbar;
