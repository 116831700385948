import React, { useEffect, useState } from "react";
import {
  Container,
  HStack,
  Center,
  Button,
  Input,
  VStack,
  Text,
  Select,
  FormControl,
} from "@chakra-ui/react";
import { useToastHook } from "src/components/toast";

//Api
import API from "src/config/axios";
import API_SLUGS from "src/config/url";

const roles = ["supervisor", "admin"];

const AddUser = ({ onClose, currentUser }) => {
  const editMode = currentUser && currentUser._id;
  const [toast, makeToast] = useToastHook();
  const [isFetching, setIsFetching] = useState(false);
  const [inputs, setInputs] = useState({
    fullName: "",
    username: "",
    password: "",
    role: "admin",
  });

  useEffect(() => {
    if (editMode) {
      let obj = {};
      const currentInputs = { ...inputs };
      delete currentInputs.password;
      Object.keys(currentInputs).map((key) => {
        obj[key] = currentUser[key];
      });
      setInputs(obj);
    }
  }, []);

  const onChangeInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const onClickSubmitBtn = async (event) => {
    event.preventDefault();
    const currentInputs = { ...inputs };
    if (editMode) {
      delete currentInputs.password;
    }
    const isValid = Object.values(currentInputs).every((item) => item !== "");
    if (isValid) {
      setIsFetching(true);
      const method = editMode ? "put" : "post";
      const url = editMode
        ? `${API_SLUGS.user}/${currentUser._id}`
        : API_SLUGS.register;
      let title = "";
      let Status = "";
      let response = await API[method](url, {
        ...inputs,
      });
      const { status, statusText } = response;
      if (editMode) {
        if (status == 200) {
          title = "کاربر مورد نظر با موفقیت ویرایش شد";
          Status = "success";
        }
        if (status == 400) {
          title = "کاربر مورد نظر یافت نشد";
          Status = "error";
        }
        onClose();
      } else {
        switch (statusText) {
          case "USER_EXIST":
            title = "کاربر مورد نظر در سیستم وجود دارد";
            Status = "error";
            setIsFetching(false);
            break;
          default:
            title = "کاربر با موفقیت در سیستم افزوده شد.";
            Status = "success";
            onClose();
            break;
        }
      }
      makeToast({
        title,
        status: Status,
      });
    } else {
      makeToast({ title: "تمامی فیلد‌ها باید پر باشد", status: "error" });
    }
  };

  const defaultRole = editMode ? currentUser.role : "admin";

  return (
    <HStack spacing="0px">
      <Center w="100%" bg="#edf2f7" p="50px" borderRadius={"2xl"}>
        <Container centerContent>
          <VStack
            p={"20px"}
            pb="40px"
            bg="white"
            borderRadius={"3xl"}
            w={"100%"}
            maxW="400px"
          >
            <FormControl>
              <form onSubmit={onClickSubmitBtn}>
                <Text
                  color="black"
                  fontSize={"md"}
                  textAlign={"right"}
                  lineHeight="10"
                >
                  نام و نام خانوادگی
                </Text>
                <Input
                  id="fullName"
                  name="fullName"
                  placeholder="نام و نام خانوادگی"
                  variant="primary"
                  onChange={onChangeInput}
                  value={inputs.fullName}
                  mb={2}
                />
                <Text
                  color="black"
                  fontSize={"md"}
                  textAlign={"right"}
                  lineHeight="10"
                >
                  نام کاربری
                </Text>
                <Input
                  id="username"
                  name="username"
                  placeholder="نام کاربری"
                  variant="primary"
                  onChange={onChangeInput}
                  value={inputs.username}
                  mb={2}
                />
                {!editMode && (
                  <>
                    <Text
                      color="black"
                      fontSize={"md"}
                      textAlign={"right"}
                      lineHeight="10"
                    >
                      کلمه عبور
                    </Text>
                    <Input
                      id="password"
                      name="password"
                      placeholder="کلمه عبور"
                      type="password"
                      variant="primary"
                      onChange={onChangeInput}
                      value={inputs.password}
                      mb={2}
                    />
                  </>
                )}
                <Text
                  color="black"
                  fontSize={"md"}
                  textAlign={"right"}
                  lineHeight="10"
                >
                  تعیین سطح دسترسی
                </Text>
                <Select
                  placeholder={defaultRole}
                  size="md"
                  name="role"
                  variant="primary"
                  onChange={(event) => {
                    if (event.target.value === "") {
                      setInputs({
                        ...inputs,
                        [event.target.name]: defaultRole,
                      });
                    } else {
                      setInputs({
                        ...inputs,
                        [event.target.name]: event.target.value,
                      });
                    }
                  }}
                >
                  {roles.map((role) => {
                    if (role !== defaultRole) {
                      return <option value={role}>{role}</option>;
                    }
                  })}
                </Select>
                <Input mt={10} size="xl" type="submit" hidden />
                <Button
                  mt={"50px"}
                  size="xl"
                  isLoading={isFetching}
                  onClick={onClickSubmitBtn}
                >
                  {editMode ? "ویرایش کاربر" : "افزودن کاربر"}
                </Button>
              </form>
            </FormControl>
          </VStack>
        </Container>
      </Center>
    </HStack>
  );
};

export default AddUser;
