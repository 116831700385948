import styled from "styled-components";
import Icon from "src/config/icon.js";

export const ProgressBar = styled.div`
  position: relative;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  outline: 1px solid rgba(209, 197, 252, 0.5);
  outline-offset: -1px;
  margin-bottom: 20px;
  ::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    width: 110px;
    height: 110px;
    border-radius: inherit;
    outline: inherit;
  }
  .progress-number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Svg = styled.svg`
  stroke-dasharray: ${(props) => props.count};
  stroke-dashoffset: 410;
  transform: rotate(-90deg);
  animation: bar-fill 1s linear forwards;
`;

export const BtnIcon = styled(Icon)`
  color: white;
  cursor: pointer;
  transition: 0.3s;
`;
